import styled, { css } from "styled-components"

const Wrapper = styled.section`
  max-width: 1200px;
  width: 75%;
  position: relative;
  background-color: ${({ theme }) => theme.white};
  z-index: 998;
  padding: 50px;
  top: -100px;
  margin: auto auto -50px;

  ${props =>
    props.noImg
      ? css`
          width: 100%;
          top: 20px;
          margin-bottom: 75px;

          @media (max-width: 500px) {
            padding: 5vw;
          }
        `
      : css`
          @media (min-width: 500px) {
            top: -125px;
            margin-bottom: -75px;
          }

          @media (min-width: 1000px) {
            top: -200px;
            margin-bottom: -150px;
          }

          @media (min-width: 1500px) {
            top: -400px;
            margin-bottom: -300px;
          }

          @media (min-width: 2000px) {
            top: -300px;
            margin-bottom: -300px;
          }

          @media (max-width: 750px) {
            width: 90%;
            padding: 20px;
            margin-top: 3rem;
          }
        `}
`

export default Wrapper
