import styled, {css} from 'styled-components'
import { Link } from "gatsby"

const Button = styled(Link)`
  border: none;
  margin-top: 1vh;
  text-decoration: none;
  padding: 15px 25px;
  cursor: pointer;
  font-weight: 500;
  text-transform: uppercase;
  color: black;
  text-align: center;
  display: inline-block;
  transition: all 100ms ease-in-out;
  
  ${({ theme }) => css`
    width: ${({ width }) => width ? width : 'auto'};
    background-color: ${theme.white};
    border: 1px solid ${theme.black};
    font-size: ${theme.m};
  `};
  
  :focus {
    outline: none;
  }
  
  :disabled, :disabled:hover {
    opacity: .7;
    cursor: default;
    background: transparent;
    color: black;
    border-color: black;
  }
  
  :hover {
    background: ${({ theme }) => theme.pink};
    border-color: ${({ theme }) => theme.pink};
    color: white;
  }
`;

export default Button;