import React from 'react';
import styled from 'styled-components';
import Img from "gatsby-image";

const ImgWrapper = styled.div`
    width: 100%;
    border-bottom: 3px solid ${({theme}) => theme.pink};
    z-index: 900;
    max-height: 500px;
    overflow: hidden;
`;

const Image = styled(Img)`
    width: 100%;
    display: block;
`;

const PageBackground = ({img}) => (
    <ImgWrapper>
        <Image fluid={img} alt="backgroud"/>
    </ImgWrapper>
);


export default PageBackground;