import React, { Component } from "react"
import styled, { css } from "styled-components"
import ParagraphItalic from "../atoms/paragraph-italic"
import Heading from "../atoms/newsletter-heading"
import Wrapper from "../atoms/page-wrapper"
import Post from "../molecules/post"
import api, { endpoint } from "../../api"
import Loading from "../molecules/loading"

const PostList = styled.section`
  display: grid;
  grid-template-columns: 1fr;
`

const Buttons = styled.section`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`

const Btn = styled.button`
  border: none;
  cursor: pointer;
  background-color: transparent;
  border-bottom: 1px solid transparent;
  padding: 5px 10px;
  transition: all 100ms linear;
  
  :focus {
    outline: none;
  }
  
  :hover {
    background: #00000010;
    transform: scale(1.1);
  }
  
  ${({ selected, theme }) => selected && css`
    border-bottom: 1px solid ${theme.black};
  `},
 
`

const ParagraphError = styled.p`
  font-size: 2rem;
  color: #dc3545;
  margin-top: 5vh;
  font-weight: 500;
`

const ArrowBtn = styled.section`
  cursor: pointer;
  transition: all 100ms linear;
  
  :hover {
    transform: scale(1.1);
  }
  
  ${
  ({ disabled }) => disabled && css`
      opacity: .5;
      cursor: default;
      
      :hover {
        transform: scale(1);
      }
    `
  }
`

class PostPage extends Component {

  state = {
    pages: 1,
    currentPage: 1,
    status: "loading",
    posts: {},
  }

  componentDidMount = () => {
    this.getPost(1)
  }

  getPost = page => {
    if (this.state.posts.hasOwnProperty(page)) {
      return this.setState({ status: "ready" })
    }

    api(endpoint.posts(page))
      .then(res => {
        const pages = parseInt(res.headers["x-wp-totalpages"])
        const posts = res.data

        this.setState(prevState => {
          return {
            status: "ready",
            posts: {
              ...prevState.posts,
              [page]: posts,
            },
            pages,
          }
        })
      })
      .catch(err => {
        this.setState({ status: "fail" })
      })
  }

  getBtn = () => {
    const { currentPage, pages } = this.state
    const items = []
    let x = currentPage + 2 > pages ? pages - 2 : currentPage

    if(pages === 1) {
      return [<Btn onClick={() => this.setPage(1)} selected={true} key={1}>1</Btn>]
    }

    for (let i = x; i < x + 1 + 2; i++) {
      items.push(<Btn onClick={() => this.setPage(i)} selected={i === currentPage} key={i}>{i}</Btn>)
    }
    return items
  }

  getPosts = () => {
    if (this.state.status === "ready") {
      return this.state.posts[this.state.currentPage].map(post => <Post key={post.id} post={post}/>)
    }

    if (this.state.status === "loading") {
      return <Loading/>
    }

    if (this.state.status === "fail") {
      return <ParagraphError>Nie mogliśmy załadować postów. <br/>Prosimy odświeżyć stronę.</ParagraphError>
    }
  }

  setPage = page => {
    if (page === this.state.currentPage) {
      return 0
    }

    if (page > 0 && page <= this.state.pages) {
      this.setState({
        currentPage: page,
        status: "loading",
      })
      this.getPost(page)
    }
  }

  render = () => (
    <Wrapper>
      <ParagraphItalic>aktualności</ParagraphItalic>
      <Heading>Najnowsze informacje</Heading>
      <PostList itemscope itemType={"http://schema.org/ItemList"}>
        {this.getPosts()}
      </PostList>

      <Buttons>
        <ArrowBtn
          onClick={() => this.setPage(this.state.currentPage - 1)}
          disabled={this.state.currentPage === 1}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill={"none"}/>
            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/>
          </svg>
        </ArrowBtn>
        {this.getBtn()}
        <ArrowBtn
          onClick={() => this.setPage(this.state.currentPage + 1)}
          disabled={this.state.currentPage === this.state.pages}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill={"none"}/>
            <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"/>
          </svg>
        </ArrowBtn>
      </Buttons>
    </Wrapper>
  )
}

export default PostPage