import React from "react"
import Heding from "../atoms/newsletter-heading"
import styled from "styled-components"
import Author from "../atoms/auth"
import Content from "../atoms/post-content"
import Button from "../atoms/button-link"

const Wrapper = styled.article`
  margin-top: 15vh;
`

const Post = ({ post }) => (
  <Wrapper itemProp={"itemListElement"} itemscope itemType={"http://schema.org/Article"}>
    <Heding itemProp={"name"}>{post.title.rendered}</Heding>
    <Author itemProp={"author"}>Aleksandra Sydor • <time dateTime={post.date}
                                                         itemProp={"dateCreated"}>{post.date.substring(0, 10)}</time>
    </Author>
    <Content itemProp={"articleBody"} dangerouslySetInnerHTML={{ __html: post.excerpt.rendered.substring(0, 600) }}/>
    <Button to={`/aktualnosci/${post.id},${post.slug}`} itemProp={"url"} title={post.title.rendered}>czytaj dalej</Button>
  </Wrapper>
)

export default Post