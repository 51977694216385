import React from "react"
import LoadingWrapper from "../atoms/loading-wrapper"
import Loading from "../atoms/loading-content"

export default () => (
  <LoadingWrapper>
    <Loading>
      <span />
      <span />
    </Loading>
    <p>Ładuję...</p>
  </LoadingWrapper>
);