import React from "react"
import Layout from "../components/organisms/layout"
import SEO from "../components/seo"
import PageBackground from "../components/atoms/page-background"
import PostPage from "../components/organisms/post-page"
import SinglePost from "../templates/post-template"
import { graphql } from "gatsby"
import { Router } from "@reach/router"

const NewsWrap = ({ data }) => (
  <Layout
    cert1={data.cert1.childImageSharp.fluid}
    cert2={data.cert2.childImageSharp.fluid}
  >
    <Router>
      <PostPageWrapper path={"/aktualnosci/"} data={data} />
      <SinglePost path={`aktualnosci/:postSlug`} data={data} />
    </Router>
  </Layout>
)

const PostPageWrapper = ({ data }) => (
  <>
    <SEO title="Aktualności" />
    <PageBackground img={data.placeholderImage.childImageSharp.fluid} />
    <PostPage />
  </>
)

export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "ContactImage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4608) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cert1: file(relativePath: { eq: "cambridge-english-assessment.png" }) {
      childImageSharp {
        fluid(maxWidth: 384, maxHeight: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cert2: file(relativePath: { eq: "direct-method.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 244, maxHeight: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default NewsWrap
